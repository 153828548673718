<template>
  <v-container class="container-card">
    <v-card>
      <Equipe />
    </v-card>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "ConsultaDepartamento",

  components: {
    Equipe: () => import("@/components/empresa/equipes/Equipe")
  },

  methods: {
    ...mapMutations({
      setView: "sistema/setView"
    })
  },

  mounted() {
    this.setView("Consulta Equipe");
  }
};
</script>
